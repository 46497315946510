/* eslint-disable react/jsx-no-target-blank */
import React from 'react';
import styled from 'styled-components';
import { Box } from '@chakra-ui/react';
import { PageStyles } from '.';
import SEO from '../components/SEO';
import { SubmitPost } from '../components/SubmitPost';
import { GenericHeader } from '../components/GenericHeader';
import { DesktopWrapper } from '../templates/Post';

export const CreatePostStyles = styled.div`
  max-width: 600px;
  width: 600px;
  @media (max-width: 840px) {
    max-width: 500px;
    width: 500px;
  }
  @media (max-width: 640px) {
    max-width: 400px;
    width: 400px;
    margin: 2rem auto 0 auto;
  }
  @media (max-width: 414px) {
    margin: 0.5rem 0 1rem 0;
    max-width: 100%;
    width: 100%;
  }
  #comedian-name {
    display: none;
    @media (max-width: 414px) {
      display: inline-block;
    }
  }
`;

export default function CreatePostPage({ location }) {
  const comedian = location
    ? location.search.slice(3).split('-').join(' ')
    : null;
  const createPost = comedian ? `Create post for ${comedian}` : 'Create post';
  const comedianName = comedian
    ? comedian
        .split(' ')
        .map((name) => name.charAt(0).toUpperCase() + name.slice(1))
        .join(' ')
    : '';
  return (
    <>
      <SEO title="Create post | Yuks" />
      <PageStyles>
        <CreatePostStyles>
          <DesktopWrapper>
            <GenericHeader header={createPost} />
          </DesktopWrapper>
          <Box className="content-wrapper" marginBottom="10px">
            <h1 style={{ fontSize: '20px', fontWeight: '700' }}>
              Create a post{' '}
              {comedian ? (
                <span id="comedian-name">for {comedianName}</span>
              ) : (
                ''
              )}
            </h1>
          </Box>
          <SubmitPost comedian={comedian} />
        </CreatePostStyles>
      </PageStyles>
    </>
  );
}
