/* eslint-disable prettier/prettier */
/* eslint-disable react/prop-types */
import { Box, Button, Grid, Flex, Input, FormControl, FormLabel, Textarea, FormHelperText } from '@chakra-ui/react';
import emailjs from 'emailjs-com';
import React, { useState } from 'react';
import styled from 'styled-components';

export const SubmitAdviceWrapperStyles = styled.div`
  label, input[type="text"], input[type="email"], textarea {
    font-size: 15px;
  }
  input[type="text"], input[type="email"] {
    padding: 1.5rem 1rem;
  }
  textarea {
    @media (max-width: 640px) {
      min-height: 150px;
    }
    @media (max-width: 414px) {
      min-height: 200px;
    }
  }
`;

export const SubmitPost = ({ comedian }) => {
  const [success, setSuccess] = useState(false);
  const [loading, setLoading] = useState(false);
  const [title, setTitle] = useState('');
  const [content, setContent] = useState('');
  const [source, setSource] = useState('');
  const [comedianName, setComedianName] = useState('');
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');

  const serviceId = process.env.GATSBY_EMAILJS_SERVICE_ID;
  const templateId = process.env.GATSBY_EMAILJS_TEMPLATE_ID;
  const userId = process.env.GATSBY_EMAILJS_USER_ID;

  const sendEmail = (e) => {
    setLoading(true);
    e.preventDefault();
    emailjs.sendForm(serviceId, templateId, e.target, userId);
    setTimeout(() => {
        setLoading(false);
        setTitle('');
        setContent('');
        setSource('');
        setComedianName('');
        setName('');
        setEmail('');
        setSuccess(true);
    }, 1000);
  }

  return (
    <Grid gridTemplateColumns="1fr">
      {success && (
        <SubmitAdviceWrapperStyles>
          <Box mt="-5px" lineHeight="1.25" fontWeight="500" color="#4056a1">Thanks for the submission.  We'll try to add it as soon as possible.</Box>
        </SubmitAdviceWrapperStyles>
      )}
      {!success && (
        <SubmitAdviceWrapperStyles>
          <form onSubmit={sendEmail}>
              <fieldset disabled={loading} style={{ border: 'none' }}>
                {/* <FormControl id="name">
                  <Input type="text" placeholder="your name (optional)" autoComplete="off" name="name" value={name} onChange={e => setName(e.target.value)} />
                </FormControl>
                <FormControl id="email" mt={4} mb={8}>
                  <Input type="email" placeholder="your email (optional)" autoComplete="off" name="email" value={email} onChange={e => setEmail(e.target.value)} />
                  <FormHelperText fontSize="13px" fontWeight="500" mt={4}>Leave your name if you want credit in the post. And leave your email for further correspondence.</FormHelperText>
                </FormControl> */}
                <FormControl id="header" style={{ display: 'none'}}>
                  <Input type="text" name="header" value="Submit post for comedian" />
                </FormControl>
                <FormControl id="comedian" style={{ display: 'none'}}>
                  <Input type="text" name="comedian" value={comedian} />
                </FormControl>
                <FormControl id="title">
                  <Input type="text" autoComplete="off" placeholder="title" name="title" value={title} onChange={e => setTitle(e.target.value)} />
                </FormControl>
                <FormControl id="content" mt={4}>
                  <Textarea placeholder="content" autoComplete="off" name="content" required value={content} onChange={e => setContent(e.target.value)} />
                </FormControl>
                <FormControl id="source" mt={2}>
                  <Input type="text" placeholder="source" autoComplete="off" name="source" value={source} onChange={e => setSource(e.target.value)} />
                </FormControl>
                {!comedian && (
                  <FormControl id="comedianName" mt={4}>
                    <Input type="text" placeholder="name of comedian" autoComplete="off" name="comedianName" value={comedianName} onChange={e => setComedianName(e.target.value)} />
                  </FormControl>
                )}
                <Flex mt={6}>
                  <Button
                    padding="18px 15px"
                    fontSize="15px"
                    type="submit"
                    colorScheme="facebook"
                    isLoading={loading}
                  >
                  Submit
                  </Button>
                </Flex>
              </fieldset>
          </form>
        </SubmitAdviceWrapperStyles>
      )}
    </Grid>
  );
};
